<template>
   <div class="paper" style="left: 0;width: 100%;">
      <div class="header" style="background: #fff; padding: 10px 20px;" >
         <div class="home-bar">
            <a  v-on:click="dash()" ><i class='bx bxs-home'></i><img :src="publicOrganizationData.logoColor[0].url"  alt=""  style="width:150px; overflow:hidden; height: 47px; display: block; float: right;"> </a>
         </div>
         <div class="control-bar row"> 
            <div class="profile">
               <a class="" data-toggle="dropdown"> 
                  <i class="bx bx-user"></i>
               </a>
               <div class="dropdown-menu">
                  <a class="dropdown-item" href="#"><strong>{{ user.replace(organizationID.replace('ORG#','').toLowerCase()+'.','') }}</strong> </a>
                  <a class="dropdown-item" href="#">- <strong v-for="(item, index) in userPermissions" :key="index">{{ item }}</strong> - </a>
                  <a class="dropdown-item" :href="'/register?smlName=forgotPasswordpm&smlPK=org'" >Change Password</a>
                  <a class="dropdown-item" href="#" v-on:click="logOut();">Log Out</a>
               </div>
            </div>
         </div>
      </div>
      <div class="smart-wrap dash"  >
         <div class="col-md-12 options" style="padding: 20px 0;" >
            <h1>{{ data.RES.attributes['responsible.name'].attributeValue}}</h1>
            <p >Email: {{ data.RES.attributes['responsible.email'].attributeValue }}</p>
            <ui-accion-button action="refresh" v-on:click="getList()"/>
         </div>
         <div class="col-md-8">
            <div class="smart-wrap col-12" v-for="(list, index) in data.Tables" :key="index" style="padding: 10px">
               <div class="smart-wrap options" style="flex-wrap: nowrap; align-items: center; margin: 0 0 10px 0;">
                  
                  <h4 style="width: 100%; font-size: 30px;">{{list.Title}}</h4>
               </div>
               <div class="list-group"   style="width: 100%;" >
                  <div class="input-wrap" v-if="false">
                     <label for="">Filter</label>
                     <select name="" id="" v-on:change="getList('')" v-model="filter">
                        <option :value="filter" v-for="(filter, findex) in filterList" :key="findex">
                           {{ filter }}
                        </option>
                     </select>
                  </div>
                  <div href="#" class="list-group-item list-group-item-action " v-for="(record, rIndex) in list.data" :key="rIndex"  >
                     <div class="smart-wrap"  style="justify-content: space-between;    flex-wrap: wrap;">
                        <div class="col-md-10" data-toggle="modal" data-target="#modal-driver" v-on:click="setgRecord([record, 'out']);">
                           <span v-if="record.subscribe">
                              <i v-if="record.subscribe == 'edit'" class='bx bxs-pencil' style='color:#5ad911'></i>
                              <i v-if="record.subscribe == 'new'" class='bx bxs-star' style='color:#0085ff'  ></i>
                           </span>
                           <p class="col-md-6 col-text" > 
                              <strong>{{ record.attributes['orderNumber'].label }}: </strong> 
                              <span > {{ record.attributes['orderNumber'].attributeValue }} </span>
                              <span > ( {{ formatData('date',record.attributes['date'].attributeValue)[0]}}  )</span>
                           </p>
                           <p class="col-md-6 col-text" > 
                              <strong>{{ record.attributes['sml.contactFullName'].label }}: </strong> 
                              <span > {{ record.attributes['sml.contactFullName'].attributeValue['contact.salutation'] +' '+record.attributes['sml.contactFullName'].attributeValue['contact.firstName']+' '+record.attributes['sml.contactFullName'].attributeValue['contact.lastName']}} </span>
                           </p>
                           <p class="col-md-6 col-text" > 
                              <strong>{{ record.attributes['provider'].label }}: </strong> 
                              <span > {{ record.attributes['provider'].attributeValue.value }} </span>
                           </p>
                           <p class="col-md-6 col-text" > 
                              <strong>Address: </strong> 
                              <span >&nbsp;{{ record.attributes['contact.streetAddress'].attributeValue }}</span>
                              <span >&nbsp;{{ record.attributes['contact.city'].attributeValue }}</span>
                              <span >&nbsp;{{ record.attributes['contact.state'].attributeValue }}</span>
                              <span >&nbsp;{{ record.attributes['contact.zipCode'].attributeValue }}</span>
                           </p>
                           <p class="col-md-6 col-text" > 
                              <strong>{{ record.attributes['status'].label }}: </strong> 
                              <span > {{ record.attributes['status'].attributeValue }} </span>
                           </p>
                           <div class="col-md-12">
                              <img :src="img.url" style="width: 25%;" alt="" v-for="(img, index) in record.attributes['pictures'].attributeValue" :key="index">
                           </div>

                        </div>
                        <div class="col-md-2" >
                           <a v-if="index==0" class="btn-g show blue absoluteCenterFlex" :style="'margin: 20px 0;cursor: pointer;background:'+publicOrganizationData.colorSeriesA[1]+';'" v-on:click="attUpdate(record, index)">
                              Take Delivery
                           </a>
                           <a v-else class="btn-g show blue absoluteCenterFlex" :style="'margin: 20px 0;cursor: pointer;background:'+publicOrganizationData.colorSeriesA[1]+';'" v-on:click="attUpdate(record, index)">
                              Release Delivery
                           </a>
                           <a v-if="index==1" class="btn-g show blue absoluteCenterFlex" :style="'margin: 20px 0;cursor: pointer;background:'+publicOrganizationData.colorSeriesA[1]+';'" v-on:click="attUpdate(record, index)">
                              Take Delivery
                           </a>
                        </div>
                     </div>
                     <div class="collapse " :id="'collapse-'+rIndex" >
                        <div class="input-wrap" v-for="(input, x) in 5" :key="x">
                           <label for="">Label</label>
                           <input type="text" placeholder="attributeValue">
                        </div>
                        <a href="" class="btn-g show blue" style="width: 200px;  display: block; padding: 10px 0; margin: 20px auto;">
                           Save
                        </a>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="velse">
         <h4>{{ organizationData.app }}</h4>
      </div>
      <paper-modal-custom/>
   </div>
</template>
<script>
let tokenQuote = null
   //AWS - GRAPHQL
   import { smd_getWebPage, smd_getRecordIndex, smd_getWorkerDash, smd_getRecordObj   } from "../../../graphql/queries";
   import { onNotify, smd_savePaymentFormContact, smd_updateAttributes  } from '../../../graphql/mutations'
   import { onUpdateSmartRecord, onUpdateRecord, onSetItemState, onUpdateAttributes } from '../../../graphql/subscriptions'
   //Tools
   import Vuex from "vuex";
   import { Auth, API,Storage } from "aws-amplify";
   import "amazon-connect-streams";
   import draggable from "vuedraggable";
   import Swal from 'sweetalert2'
   import { loading, popUp, formatData  } from "../../../store/tools";

   //Components
   import PaperDisplayer from "../../../components/papers/PaperDisplayer.vue";
   import PaperLayout from "../../../components/papers/PaperLayout.vue";
   import PaperUser from "../../../components/papers/PaperUser.vue";
   import PaperModalCustom from "../../../components/papers/PaperModalCustom.vue";
   
   import UiTable from "../../../components/ui/UiTable.vue";
   import UiAccionButton from "../../../components/ui/UiAccionButton.vue";

   export default {
      components: {
         PaperDisplayer,
         PaperLayout,
         UiTable,
         PaperUser,
         PaperModalCustom,
         UiAccionButton,
      },
      data() {
         return {
            //Record
            list:[],
            filterTable:[],
            filterList:[],
            filter:'',
            data: undefined,
            sml:'',
            hostUrl:'',
            webSite: undefined,
            renderWeb: false,
            widthDisplayWebSite:'100%',
            //
            tandc: false,
            saveCard: false,
            
            methodPayment:'',
         };
      },
      created() {
         this.hostUrl = location.origin
         loading('show', 'webPreview')
         this.setUrlHistory('worker')
         if ( this.organizationID == 'Not authorized') {
            this.$router.push({
               path: "/login",
            });
         } else {
            this.getList()
            this.subscribe()
         }
      },
      methods: {
         ...Vuex.mapMutations([
            'setWebSite',
            'SetOrganizationID',
            'SetOrganizationData',
            'setPublicOrganization',
            'setPublicOrganizationData',
            'setgRecord',
            'setUrlHistory'
         ]),
         ...Vuex.mapActions([
            'getPublicOrganization'
         ]),
         formatData(format, data){
            return formatData(format, data)
         },
         //Init
         async setUserWeb(){
            Auth.signIn('smd_webuser', '383@JDU45ss#')
            .then((user) => { 
               //Clear Global Variables
               this.SetOrganizationID("Not authorized")
               this.SetOrganizationData("Not authorized")
               this.setPublicOrganization(undefined)
               this.setPublicOrganizationData(undefined)
               //console.clear()
               ///-----
               //this.getPublicOrganization()
               setTimeout(() => {
                  this.getSML()
                  //this.getList()
               }, 1500);
              //this.loading('hidden')
            })
            .catch((err) => console.log(err))
         },
         async getList(filter){
            //console.clear()
            loading('show', 'webPreview')
            let pushData = {};
            let pullData = ''
            try {
               console.log('this.attUser',this.attUser);
               pushData = {
                  PK: this.organizationID,
                  SK: this.attUser['custom:responsibleSK']//'USR#driver2'//this.attUser['custom:GSP1PK1'],
               }
               console.log('pushData Get Layout:', pushData);
               pullData = await API.graphql({
                  query: smd_getWorkerDash,
                  variables: pushData
               });
               pullData = pullData.data.smd_getWorkerDash
               pullData = JSON.parse(pullData.data)
               console.log(pullData);
               let data = pullData
               for (let i = 0; i <  data.Tables.length; i++) {
                  for (let j = 0; j < data.Tables[i].data.length; j++) {
                     if (!this.filterList.includes(data.Tables[i].data[j].attributes.status.attributeValue) && data.Tables[i].data[j].attributes.status.attributeValue != 'Unassigned' && data.Tables[i].data[j].attributes.status.attributeValue != '' ) {
                        this.filterList.push(data.Tables[i].data[j].attributes.status.attributeValue);
                     }
                  }
               }
               this.data = data
               loading('hide', 'webPreview')
            } catch (error) {
               console.log(error);
               popUp(error, 'cach', 'err')
            }
            loading('hide', 'webPreview')
         },
         async logOut() {
            loading('show')
            //this.clear()
            try {
            await Auth.signOut();
            setTimeout(() => {
               console.log("salir");
               this.$router.push({
                  path: "/login",
               });
               loading('hide')
            }, 1000);
            } catch (error) {
            //console.log(error);
            loading('hide')
            Swal.fire({
               icon: 'error',
               title: 'Something went wrong!' ,
               text: error,
               footer: 'smd_getMyOrganization'
            })
            }
         },
         //page
         setValue(data){
            let value = data
            try {
               //value = JSON.parse(value).value
            } catch (error) {
               
            }
            return value
         },
         async subscribe() {
            API.graphql({  query: onUpdateSmartRecord, variables: { PK: this.organizationID }  }).subscribe({
               next: (eventData) => {
                  try {
                     let pullData = eventData.value.data.onUpdateSmartRecord;
                     let subRecord =JSON.parse(pullData.data)
                     //Process subRecord
                     //for (const key in subRecord.attributes) {
                        //subRecord[key] = subRecord.attributes[key].attributeValue
                     //}
                     //subRecord = recordToDisplay(subRecord)
                     //-- Sub in table
                     let exist = false
                     console.log(subRecord);
                     if (subRecord.PK == this.organizationID   ) {
                        console.log('** NEW SUB: '+ subRecord.shortEntity ,subRecord);
                        for (let i = 0; i <  this.data.Tables.length; i++) {
                           for (let j = 0; j < this.data.Tables[i].data.length; j++) {
                              if (this.data.Tables[i].data[j].SK == subRecord.SK) {
                                 this.data.Tables[i].data.splice(j, 1)
                                 subRecord.subscribe = 'edit'
                                 exist = true
                                 if ( subRecord.attributes.status.attributeValue != "Unassigned" && this.data.RES.SK == subRecord.attributes.responsible.attributeValue.SK) {
                                    this.data.Tables[1].data.splice(0, 0, subRecord)
                                 }
                                 if( subRecord.attributes.status.attributeValue == "Unassigned" ){
                                    this.data.Tables[0].data.splice(0, 0, subRecord)
                                 }
                                 //this.data.Tables[i].data[j] = subRecord
                              }
                           }
                        }
                        if (exist == false) {
                           subRecord.subscribe = 'new'
                           if (!subRecord.attributes.responsible.attributeValue.SK) {
                              this.data.Tables[0].data.splice(0, 0, subRecord)
                           }
                           else if (this.data.RES.SK == subRecord.attributes.responsible.attributeValue.SK) {
                              this.data.Tables[1].data.splice(0, 0, subRecord)
                           }
                        }
                     }else{
                        if (subRecord.PK == '#SMARTDASH' && this.userPermissions.includes('Superadmin')) {
                        //console.log('** NEW SUB ADMIN: '+ subRecord.shortEntity ,subRecord);
                        
                        }else{
                        //console.log('no son iguales')
                        }
                     }
                  } catch (error) {
                     console.log(error);
                  }
               }
            });

            API.graphql({  query: onUpdateAttributes  }).subscribe({
               next: (eventData) => {
                  try {
                     let pullData = eventData.value.data.onUpdateAttributes;
                     let subRecord =JSON.parse(pullData.data)
                     let exist = false
                     console.log(subRecord);
                     if (subRecord.PK == this.organizationID   ) {
                        console.log('** NEW SUB: '+ subRecord.shortEntity ,subRecord);
                        for (let i = 0; i <  this.data.Tables.length; i++) {
                           for (let j = 0; j < this.data.Tables[i].data.length; j++) {
                              if (this.data.Tables[i].data[j].SK == subRecord.SK) {
                                 this.data.Tables[i].data.splice(j, 1)
                                 subRecord.subscribe = 'edit'
                                 exist = true
                                 if ( this.data.RES.SK == subRecord.attributes.responsible.attributeValue.SK) {
                                    this.data.Tables[1].data.splice(0, 0, subRecord)
                                 }
                                 else{
                                    this.data.Tables[0].data.splice(0, 0, subRecord)
                                 }
                                 //this.data.Tables[i].data[j] = subRecord
                              }
                           }
                        }
                        if (exist == false) {
                           subRecord.subscribe = 'new'
                           if (!subRecord.attributes.responsible.attributeValue.SK) {
                              this.data.Tables[0].data.splice(0, 0, subRecord)
                           }
                           else if (this.data.RES.SK == subRecord.attributes.responsible.attributeValue.SK) {
                              this.data.Tables[1].data.splice(0, 0, subRecord)
                           }
                        }
                     }else{
                        if (subRecord.PK == '#SMARTDASH' && this.userPermissions.includes('Superadmin')) {
                        //console.log('** NEW SUB ADMIN: '+ subRecord.shortEntity ,subRecord);
                        
                        }else{
                        //console.log('no son iguales')
                        }
                     }
                  } catch (error) {
                     console.log(error);
                  }
               }
            });

            API.graphql({ query: onSetItemState }).subscribe({
            next: (eventData) => {
               let pullData = eventData.value.data.onSetItemState;
               let subRecord =JSON.parse(pullData.data)
               //Process subRecord
               //for (const key in subRecord.attributes) {
                  //subRecord[key] = subRecord.attributes[key].attributeValue
               //}
               //subRecord = recordToDisplay(subRecord)
               //-- Sub in table
               let exist = false
               console.log(subRecord);
               if (subRecord.PK == this.organizationID   ) {
                  console.log('** NEW SUB: '+ subRecord.shortEntity ,subRecord);
                  for (let i = 0; i <  this.data.Tables.length; i++) {
                     for (let j = 0; j < this.data.Tables[i].data.length; j++) {
                        if (this.data.Tables[i].data[j].SK == subRecord.SK) {
                           subRecord.subscribe = 'edit'
                           //exist = true
                           this.data.Tables[i].data= splice(j, 1)
                        }
                     }
                  }
               }else{
                  if (subRecord.PK == '#SMARTDASH' && this.userPermissions.includes('Superadmin') ) {
                  //console.log('** NEW SUB ADMIN: '+ subRecord.shortEntity ,subRecord);
                  
                  }else{
                  //console.log('no son iguales')
                  }
               }
               setTimeout(() => { this.renderComponent = true }, 100);
            }
            });
            
         },
         //Edit
         async attUpdate(data, condition){
            loading('show', 'getItem')
            let pullData = ''
            let pushData = ''
            let att = []
            console.log(data.attributes.status.attributeValue);
            if (condition == 1) {    
               att =[
                  { 
                     attributeName: "responsible", 
                     attributeType: "L", 
                     attributeValue: [], 
                  },
               ]
            }else{   
               att =[
                  { 
                     attributeName: "responsible", 
                     attributeType: "L", 
                     attributeValue: JSON.stringify({PK: this.organizationID, SK: this.attUser['custom:responsibleSK'], value: this.data.RES.attributes['responsible.name'].attributeValue, id: this.user}), 
                  },
               ]
            }
            pushData={
               PK: data.PK,   
               SK: data.SK ,
               attributes: JSON.stringify(att) ,
            }
            console.log('pushData: ',pushData);
            try {
               pullData = await API.graphql({
                  query: smd_updateAttributes, //smd_updateSmartRecord
                  variables: { 
                     PK: data.PK,
                     SK: data.SK ,
                     attributes: JSON.stringify(att) ,
                  },
               }
            );
            console.log(pullData);
            this.getList()
            } catch (error) {
               console.log(error);
               popUp(error, 'cach', 'err')
            }
            loading('hidde', 'getItem')
         }, 
      },
      computed: {
         ...Vuex.mapState([
            "organizationID",
            "publicOrganization",
            "attUser",
            "publicOrganizationData",
            "organizationData",
            'user',
            "dataUser",
            "userPermissions",
            'publicOrgPay'
         ]),
      },
      watch: {
         
      },
   };

</script>
